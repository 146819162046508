document.addEventListener('DOMContentLoaded', function() {

  var busquedaAvanzadaSelector = document.querySelector('#busqueda-avanzada-selector');
  
  busquedaAvanzadaSelector.addEventListener('click', function() {
	    // Obtén el elemento del div por su id
	    const buscadorGeneral = document.getElementById('buscador-general');

	    // Si el div tiene la clase 'height-fit-content', remuévela, de lo contrario, añádela
	    if (buscadorGeneral.classList.contains('height-fit-content')) {
	        buscadorGeneral.classList.remove('height-fit-content');
	    } else {
	        buscadorGeneral.classList.add('height-fit-content');
	    }
	});
});